import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Modal, ToggleButton, ButtonGroup, ToggleButtonGroup, Alert} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useTiersPayant} from "../../context/TiersPayantContext";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-date-picker";
import useToken from "../../hooks/useToken";

const apiURL = process.env.REACT_APP_API_URL

const RestartRefundTiersPayant = ({handleClose, tiersPayant}) => {
    const { t } = useTranslation();
    const [isPaid, setIsPaid] = useState()
    const [hasCompliantFiles, setHasCompliantFiles] = useState()
    const [sendingType, setSendingType] = useState()
    const [emails, setEmails] = useState([])
    const [defaultEmails, setDefaultEmails] = useState([])
    const [howToManage, setHowToManage] = useState(null)
    const [showProofOfPaymentFile, setShowProofOfPaymentFile] = useState(true)
    const {loading, apiErrors, restartRefund, resetApiErrors} = useTiersPayant()
    const {token} = useToken()

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
    } = useForm({})

    useEffect(() => {

        if (tiersPayant.status === 'paid') {
            setValue('isPaid', 'true')
            setIsPaid(true)
        } else if (tiersPayant.status === 'restart_refund_impossible_internal') {
            setValue('isPaid', 'false')
            setIsPaid(false)
        } else if (tiersPayant.status === 'restart_refund_impossible_ask_document') {
            setValue('isPaid', 'false')
            setIsPaid(false)
        } else if (tiersPayant.status === 'restart_refund_impossible_ask_invoice') {
            setValue('isPaid', 'false')
            setIsPaid(false)
        } else if (tiersPayant.status === 'refund_restarted') {
            setValue('isPaid', 'false')
            setIsPaid(false)
        }

        if (tiersPayant.hasCompliantFiles === true) {
            setValue('hasCompliantFiles', 'true')
            setHasCompliantFiles(true)
        } else if (tiersPayant.hasCompliantFiles === false) {
            setValue('hasCompliantFiles', 'false')
            setHasCompliantFiles(false)
        }

        if (tiersPayant.proofOfPaymentFile) {
            showProofOfPaymentFile(false)
        }
        if (tiersPayant.amountPaid) {
            setValue('amountPaid', tiersPayant.amountPaid)
        }
        if (tiersPayant.paymentDate) {
            setValue('paymentDate', tiersPayant.paymentDate)
        }

        if (tiersPayant.restartSendingType) {
            setValue('sendingType', tiersPayant.restartSendingType)
            setSendingType(tiersPayant.restartSendingType)
        }

        if (tiersPayant.restartHowToManage) {
            setValue('howToManage', tiersPayant.restartHowToManage)
            setHowToManage(tiersPayant.restartHowToManage)
        }

        const options = tiersPayant.healthInsurance.emails.map((email => {
            return { label: email.email, value: email.id }
        }))
        setEmails(options)

        if (tiersPayant.restartSendTo !== null) {
            const sendToArray = tiersPayant.restartSendTo.split(';')
            const defaultOptions = options.filter(element => sendToArray.some(v => element.label.includes(v)))

            setDefaultEmails(defaultOptions)
        }
    }, [tiersPayant]);

    const onSubmit = ({isPaid, hasCompliantFiles, sendingType, restartSendTo, howToManage, paymentDate, amountPaid, proofOfPaymentFile}) => {
        const isPaidBool = isPaid === undefined ? null : (isPaid === 'true');
        const hasCompliantFilesBool = hasCompliantFiles === undefined ? null : (hasCompliantFiles === 'true');
        const stringDate = paymentDate === undefined ? null : paymentDate.toLocaleDateString("fr").replaceAll('/', '-')
        //const sendToIds = restartSendTo === undefined ? null : Array.from(restartSendTo, (contact => contact.value))

        restartRefund(
            tiersPayant.id,
            {
                isPaid: isPaidBool,
                hasCompliantFiles: hasCompliantFilesBool,
                sendingType,
                restartSendTo,
                howToManage,
                paymentDate: stringDate,
                amountPaid,
                proofOfPaymentFile
            }
        )
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="tiers-payant.is-paid">
                <Form.Label>{t('tiers-payant.manage.form.is-paid.label')}</Form.Label>
                <Controller
                    name="isPaid"
                    control={control}
                    render={({ field, value, name, ref }) => (
                        <ToggleButtonGroup
                            type="radio"
                            value={field.value}
                            onChange={(val) => {
                                field.onChange(val)
                                setIsPaid(val === 'true')
                            }}
                            name="isPaid"
                            ref={field.ref}
                            style={{marginLeft: '10px'}}
                        >
                            <ToggleButton id="isPaid-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                            <ToggleButton id="isPaid-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                        </ToggleButtonGroup>
                    )}
                    size="lg"
                    {...register("isPaid", {
                        required: 'error-empty',
                    })}
                />
            </Form.Group>

            {isPaid === true && <>
                <Form.Group className="mb-3 col-md-6" controlId="tiers-payant.payment-date">
                    <Form.Label>{t('tiers-payant.manage.form.payment-date.label')}</Form.Label>
                    <Controller
                        name="paymentDate"
                        control={control}
                        render={({field, value, name, ref}) => (
                            <DatePicker
                                onChange={field.onChange}
                                value={field.value}
                                className={`p-0 form-control form-control-lg ${formErrors && formErrors.paymentDate ? ' is-invalid' : ''}`}
                                format={"dd-MM-y"}
                            />
                        )
                        }
                        size="lg"
                        {...register("paymentDate", {
                            required: 'error-empty',
                            //onChange: (event) => handleDate(event),
                        })}

                    />
                    {formErrors && formErrors.paymentDate && (
                        <Form.Control.Feedback type="invalid">
                            {t(`tiers-payant.manage.form.payment-date.errors.${formErrors.paymentDate.message}`)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group className="mb-3 col-md-6" controlId="tiers-payant.amountPaid">
                    <Form.Label>{t('tiers-payant.manage.form.amount-paid.label')}</Form.Label>
                    <Form.Control
                        type="float"
                        placeholder={t('tiers-payant.manage.form.amount-paid.place-holder')}
                        size="lg"
                        {...register("amountPaid", {
                            required: 'error-empty',
                        })}
                        disabled={loading}
                        isInvalid={formErrors && formErrors.amountPaid}
                    />
                    {formErrors && formErrors.amountPaid && (
                        <Form.Control.Feedback type="invalid">
                            {t(`tiers-payant.manage.form.amount-paid.errors.${formErrors.amountPaid.message}`)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="tiers-payant.proof-of-payment-file">
                    <Form.Label>
                        {t('tiers-payant.manage.form.proof-of-payment-file.label')}
                    </Form.Label>

                    {tiersPayant.proofOfPaymentFile && (
                        <div>
                            {t('tiers-payant.manage.form.proof-of-payment-file.already-downloaded')}
                            <a target="_blank" href={`${apiURL}/api/tiers-payants/${tiersPayant.id}/file/${tiersPayant.proofOfPaymentFile}?token=${token}`}>
                                {tiersPayant.proofOfPaymentFile}</a>
                            {!showProofOfPaymentFile && <div><a style={{color: '#4287f5'}} onClick={() => setShowProofOfPaymentFile(!showProofOfPaymentFile)}>Le modifier ?</a></div>}
                        </div>
                    )}

                    {showProofOfPaymentFile && (<>
                        <Form.Control
                            type="file"
                            size="lg"
                            {...register("proofOfPaymentFile", {
                                required: 'error-empty',
                            })}
                            disabled={loading}
                            isInvalid={formErrors && formErrors.proofOfPaymentFile}
                        />
                        {formErrors && formErrors.proofOfPaymentFile && (
                            <Form.Control.Feedback type="invalid">
                                {t(`tiers-payant.manage.form.proof-of-payment-file.errors.${formErrors.proofOfPaymentFile.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </>)}
                </Form.Group>
            </>}

            {isPaid === false && <>
                <Form.Group className="mb-3" controlId="tiers-payant.how-to-manage-restart">
                    <Form.Label>{t('tiers-payant.manage.form.how-to-manage-restart.label')}</Form.Label>
                    <Controller
                        name="howToManage"
                        control={control}
                        render={({ field, value, name, ref }) => (
                            <ToggleButtonGroup
                                type="radio"
                                value={field.value}
                                onChange={(val) => {
                                    field.onChange(val)
                                    setHowToManage(val)
                                }}
                                name="howToManage"
                                ref={field.ref}
                                style={{marginLeft: '10px'}}
                            >
                                <ToggleButton id="howToManage-btn-1" value='cancel' variant='outline-dark'>Annuler</ToggleButton>
                                <ToggleButton id="howToManage-btn-2" value='paid' variant='outline-dark'>Payé à l'assuré</ToggleButton>
                                <ToggleButton id="howToManage-btn-3" value='irrecoverable' variant='outline-dark'>Irrécouvrable</ToggleButton>
                                <ToggleButton id="howToManage-btn-4" value='restart-refund' variant='outline-info'>Relancé à nouveau</ToggleButton>
                            </ToggleButtonGroup>
                        )}
                        size="lg"
                        {...register("howToManage", {
                            required: 'error-empty',
                        })}
                    />
                </Form.Group>

                {howToManage !== null && howToManage !== 'restart-refund' && <>
                    <Alert variant="warning">
                        <div className="alert-message">
                            <div className="block">
                                Attention, le dossier <strong>va être clôturé</strong> dans l'application.
                            </div>
                        </div>
                    </Alert>
                    </>}

                {howToManage === 'restart-refund' && <>
                    <Form.Group className="mb-3" controlId="tiers-payant.has-compliant-files">
                        {tiersPayant.type === 'care' && <Form.Label>
                            Merci de télécharger et de vérifier <span style={{textDecorationLine: 'underline'}}>si la mutuelle saisie correspond à la carte mutuelle scannée</span>.<br/> <span style={{color: "rgb(235, 107, 107)", fontWeight: "bold"}}>Est ce que le dossier est conforme ?</span>
                        </Form.Label>}
                        {tiersPayant.type === 'prosthesis_orthodontics' && <Form.Label>
                            Merci de télécharger et de vérifier <span style={{textDecorationLine: 'underline'}}>si la prise en charge est conforme à la facture</span>.<br/>
                            Pour rappel:
                            <ul>
                                <li>La prise en charge doit être signée (pour les envois mail)</li>
                                <li>Les montants indiqués entre la prise en charge et la facture doivent correspondre</li>
                                <li>La date de réalisation des actes doit être pendant la période de validité de la prise en charge</li>
                                <li>la prise en charge doit être facturable en ligne (pour les envois par portail web)</li>
                            </ul>
                            <span style={{color: "rgb(235, 107, 107)", fontWeight: "bold"}}>Est ce que le dossier est conforme ?</span>
                        </Form.Label>}

                        <Controller
                            name="hasCompliantFiles"
                            control={control}
                            render={({ field, value, name, ref }) => (
                                <ToggleButtonGroup
                                    type="radio"
                                    value={field.value}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        setHasCompliantFiles(val === 'true')
                                    }}
                                    name="hasCompliantFiles"
                                    ref={field.ref}
                                    style={{marginLeft: '10px'}}
                                >
                                    <ToggleButton id="hasCompliantFiles-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                    <ToggleButton id="hasCompliantFiles-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            size="lg"
                            {...register("hasCompliantFiles", {
                                required: 'error-empty',
                            })}
                        />
                    </Form.Group>

                    {hasCompliantFiles === false && <Alert variant="warning">
                        <div className="alert-message">
                            <div className="block">
                                <strong>Un dossier non conforme doit être re fait depuis le début.</strong>
                            </div>
                            <div className="block sm:inline">
                                En confirmant, le dossier sera réinitialiser et prendra le statut "Nouveau - à refaire".
                            </div>
                        </div>
                    </Alert>
                    }

                    {hasCompliantFiles === true && <>
                        <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                            <Form.Label>{t('tiers-payant.manage.form.sending-type-restart.label')}</Form.Label>
                            <Controller
                                name="sendingType"
                                control={control}
                                render={({ field, value, name, ref }) => (
                                    <>
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="mail"
                                            ref={field.ref}
                                            value="mail"
                                            label={t('tiers-payant.manage.form.sending-type.mail')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'mail'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="web_portal"
                                            ref={field.ref}
                                            value="web_portal"
                                            label={t('tiers-payant.manage.form.sending-type.web_portal')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'web_portal'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="letter"
                                            ref={field.ref}
                                            value="letter"
                                            label={t('tiers-payant.manage.form.sending-type.letter')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'letter'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="mail_phone"
                                            ref={field.ref}
                                            value="mail_phone"
                                            label={t('tiers-payant.manage.form.sending-type.mail_phone')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'mail_phone'}
                                        />
                                    </>
                                )}
                                size="lg"
                                {...register("sendingType", {
                                    required: 'error-empty',
                                    onChange: (val) => {
                                        setSendingType(val.target.value)
                                    }
                                })}
                            />
                        </Form.Group>

                        {sendingType === 'mail' && <>
                            <Form.Group className="mb-3" controlId="tiers-payant.send-to">
                                <Form.Label>{t('tiers-payant.manage.form.send-to.label')}</Form.Label>
                                <Controller
                                    name="restartSendTo"
                                    control={control}
                                    size="lg"
                                    {...register("restartSendTo", {
                                        //required: 'error-empty',
                                    })}
                                    render={({ field, value, name, ref }) => (
                                        <CreatableSelect
                                            fieldName={field.name}
                                            defaultValue={defaultEmails}
                                            placeholder={t('tiers-payant.manage.form.send-to.placeholder')}
                                            inputRef={field.ref}
                                            options={emails}
                                            value={emails.find((c) => c.value === field.value)}
                                            onChange={val => field.onChange(val.value)}
                                            isInvalid={formErrors && formErrors.restartSendTo}
                                            className={`p-0 form-control form-control-lg ${formErrors && formErrors.restartSendTo ? ' is-invalid' : ''}`}
                                        />)
                                    }
                                />
                                {formErrors && formErrors.restartSendTo && (
                                    <Form.Control.Feedback type="invalid">
                                        {t(`tiers-payant.manage.form.send-to.errors.${formErrors.restartSendTo.message}`)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </>}
                    </>}
                </>}
            </>}

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.manage.button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('tiers-payant.manage.button.next')}
                </Button>
            </Modal.Footer>
        </Form>
    )
}

export default RestartRefundTiersPayant