import {Alert, Button, Form, Modal, ProgressBar, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useToken from "../../../hooks/useToken";

const options = [
    { label: 'Soins', value: 'care' },
    { label: 'Prothèses / Orthodontie', value: 'prosthesis_orthodontics' }
]

const apiURL = process.env.REACT_APP_API_URL

const Step1Document = ({tiersPayant, loading, handleClose, onSubmit, apiErrors, currentStep, stepsConfig, onClickStep}) => {
    const { t } = useTranslation();
    const [type, setType] = useState(0)
    const [showInvoiceFile, setShowInvoiceFile] = useState(true)
    const [showInsuranceCardFile, setShowInsuranceCardFile] = useState(true)
    const [hasInsuranceCardFile, setHasInsuranceCardFile] = useState(true)
    const [showMedicalCareFile, setShowMedicalCareFile] = useState(true)
    const [hasMedicalCareFile, setHasMedicalCareFile] = useState(true)
    const [needToValidateRemainingAmount, setNeedToValidateRemainingAmount] = useState(false)
    const [isRemainingAmountIsValid, setIsRemainingAmountIsValid] = useState(false)
    const {token} = useToken()

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
        setError,
    } = useForm({})

    useEffect(() => {
        if (apiErrors !== null && Object.keys(apiErrors).length > 0) {
            Object.keys(apiErrors).map((key) => {
                setError(key, apiErrors[key])
            })
        }
    }, [apiErrors]);

    useEffect(() => {
        if (tiersPayant) {
            if (tiersPayant.type) {
                setValue('type', tiersPayant.type)
                setType(tiersPayant.type)
            }
            if (tiersPayant.invoiceFile) {
                setShowInvoiceFile(false)
            }

            if (false === tiersPayant.hasInsuranceCardFile) {
                setValue('hasInsuranceCardFile', true)
                setHasInsuranceCardFile(false)
            }
            if (tiersPayant.insuranceCardFile && true === tiersPayant.hasInsuranceCardFile) {
                setShowInsuranceCardFile(false)
                setHasInsuranceCardFile(true)
            }

            if (false === tiersPayant.hasMedicalCareFile) {
                setValue('hasMedicalCareFile', true)
                setHasMedicalCareFile(false)
            }
            if (tiersPayant.medicalCareFile && true === tiersPayant.hasMedicalCareFile) {
                setShowMedicalCareFile(false)
                setHasMedicalCareFile(true)
            }

            if (true === tiersPayant.isRemainingAmountIsValid)
            {
                setValue('isRemainingAmountIsValid', 'true')
                setIsRemainingAmountIsValid(true)
            }
            if (false === tiersPayant.isRemainingAmountIsValid)
            {
                setValue('isRemainingAmountIsValid', 'false')
                setIsRemainingAmountIsValid(false)
            }
            setNeedToValidateRemainingAmount((tiersPayant && tiersPayant.type === 'care' && tiersPayant.remainingAmount >= 50) || (tiersPayant && tiersPayant.type === 'prosthesis_orthodontics' && tiersPayant.remainingAmount <= 50))
        }
    }, [tiersPayant]);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="tiers-payant.nirCode">
                    <Form.Label>{t('tiers-payant.manage.form.type.label')}</Form.Label>
                    <Controller
                        control={control}
                        render={({field, value, name, ref}) => (
                            <Select
                                name={field.name}
                                placeholder={t('tiers-payant.manage.form.type.place-holder')}
                                inputRef={field.ref}
                                options={options}
                                value={options.find((c) => c.value === field.value)}
                                onChange={val => {
                                    field.onChange(val.value)
                                    setType(val.value)
                                    setNeedToValidateRemainingAmount((val.value === 'care' && tiersPayant.remainingAmount >= 50) || (val.value === 'prosthesis_orthodontics' && tiersPayant.remainingAmount <= 50))
                                }}
                                isInvalid={formErrors && formErrors.type}
                                className={`p-0 form-control form-control-lg ${formErrors && formErrors.type ? ' is-invalid' : ''}`}
                            />)
                        }
                        size="lg"
                        {...register("type", {
                            required: 'error-empty',
                        })}
                    />
                    {formErrors && formErrors.nirCode && (
                        <Form.Control.Feedback type="invalid">
                            {t(`tiers-payant.manage.form.type.errors.${formErrors.nirCode.message}`)}
                        </Form.Control.Feedback>
                    )}
                    </Form.Group>

                {type === 'care' && needToValidateRemainingAmount && <div>
                    <Form.Group className="mb-3" controlId="tiers-payant.is-cancel-on-client-care">

                        <Alert variant="warning">
                            <div className="alert-message">
                                <Form.Label>{t('tiers-payant.manage.form.need-to-validate-remaining-amount-care.label')}</Form.Label>

                                <Controller
                                    name="isRemainingAmountIsValid"
                                    control={control}
                                    className="form-check"
                                    render={({ field, value, name, ref }) => (
                                        <ToggleButtonGroup
                                            type="radio"
                                            value={field.value}
                                            onChange={(val) => {
                                                field.onChange(val)
                                                setIsRemainingAmountIsValid(val === 'true')
                                            }}
                                            name="isRemainingAmountIsValid"
                                            ref={field.ref}
                                            style={{marginLeft: '10px'}}
                                            isInvalid={formErrors && formErrors.isRemainingAmountIsValid}
                                        >
                                            <ToggleButton id="isRemainingAmountIsValid-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                            <ToggleButton id="isRemainingAmountIsValid-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                        </ToggleButtonGroup>
                                    )}
                                    size="lg"
                                    {...register("isRemainingAmountIsValid", {
                                        required: 'error-empty',
                                    })}
                                />
                                {formErrors && formErrors.isRemainingAmountIsValid && (
                                    <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                                        {t(`tiers-payant.manage.form.isRemainingAmountIsValid.errors.${formErrors.isRemainingAmountIsValid.message}`)}
                                    </Form.Control.Feedback>
                                )}
                            </div>
                        </Alert>

                    </Form.Group>
                </div>}

                {type === 'prosthesis_orthodontics' && needToValidateRemainingAmount && <div>
                    <Form.Group className="mb-3" controlId="tiers-payant.is-cancel-on-client-software">
                        <Alert variant="warning">
                            <div className="alert-message">
                            <Form.Label>{t('tiers-payant.manage.form.need-to-validate-remaining-amount-prosthesis-orthodontics.label')}</Form.Label>
                            <Controller
                                name="isRemainingAmountIsValid"
                                control={control}
                                render={({ field, value, name, ref }) => (
                                    <ToggleButtonGroup
                                        type="radio"
                                        value={field.value}
                                        onChange={(val) => {
                                            field.onChange(val)
                                            setIsRemainingAmountIsValid(val === 'true')
                                        }}
                                        name="isRemainingAmountIsValid"
                                        ref={field.ref}
                                        style={{marginLeft: '10px'}}
                                        isInvalid={true}
                                    >
                                        <ToggleButton id="isRemainingAmountIsValid-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                        <ToggleButton id="isRemainingAmountIsValid-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                                size="lg"
                                {...register("isRemainingAmountIsValid", {
                                    required: 'error-empty',
                                })}
                            />
                            {formErrors && formErrors.isRemainingAmountIsValid && (
                                <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                                    {t(`tiers-payant.manage.form.isRemainingAmountIsValid.errors.${formErrors.isRemainingAmountIsValid.message}`)}
                                </Form.Control.Feedback>
                            )}
                            </div>
                        </Alert>
                    </Form.Group>
                </div>}

                {type !== 0 && (!needToValidateRemainingAmount || needToValidateRemainingAmount && isRemainingAmountIsValid) && <>
                    {type !== 0 && (
                        <Form.Group className="mb-3" controlId="tiers-payant.invoice-file">

                            <Form.Label>
                                {t('tiers-payant.manage.form.invoice-file.label')}
                            </Form.Label>
                            {tiersPayant.invoiceFile && (
                                <div>
                                    Fichier téléchargé: <a target="_blank" href={`${apiURL}/api/tiers-payants/${tiersPayant.id}/file/${tiersPayant.invoiceFile}?token=${token}`}>{tiersPayant.invoiceFile}</a>
                                    {!showInvoiceFile && (<div><a style={{color: '#4287f5'}} onClick={() => setShowInvoiceFile(!showInvoiceFile)}>Le modifier ?</a></div>)}
                                </div>
                            )}

                            {showInvoiceFile && (
                                <>
                                    <Form.Control
                                        type="file"
                                        size="lg"
                                        {...register("invoiceFile", {
                                            required: 'error-empty',
                                        })}
                                        disabled={loading}
                                        isInvalid={formErrors && formErrors.invoiceFile}
                                    />
                                    {formErrors && formErrors.invoiceFile && (
                                        <Form.Control.Feedback type="invalid">
                                            {t(`tiers-payant.manage.form.invoice-file.errors.${formErrors.invoiceFile.message}`)}
                                        </Form.Control.Feedback>
                                    )}
                                </>)}
                        </Form.Group>
                    )}
                    {type === 'care' && (
                        <Form.Group className="mb-3" controlId="tiers-payant.insurance-card-file">
                            <Form.Label>
                                {t('tiers-payant.manage.form.insurance-card-file.label')}
                            </Form.Label>

                            {tiersPayant.insuranceCardFile && (
                                <div>
                                    {t('tiers-payant.manage.form.insurance-card-file.already-downloaded')}
                                    <a target="_blank" href={`${apiURL}/api/tiers-payants/${tiersPayant.id}/file/${tiersPayant.insuranceCardFile}?token=${token}`}>
                                    {tiersPayant.insuranceCardFile}</a>
                                    {!showInsuranceCardFile && <div><a style={{color: '#4287f5'}} onClick={() => setShowInsuranceCardFile(!showInsuranceCardFile)}>Le modifier ?</a></div>}
                                </div>
                            )}

                            {showInsuranceCardFile && (<>
                                {hasInsuranceCardFile && <>
                                    <Form.Control
                                        type="file"
                                        placeholder={t('tiers-payant.manage.form.insurance-card-file.place-holder')}
                                        size="lg"
                                        {...register("insuranceCardFile", {
                                            required: 'error-empty',
                                        })}
                                        disabled={loading}
                                        isInvalid={formErrors && formErrors.insuranceCardFile}
                                    />
                                    {formErrors && formErrors.insuranceCardFile && (
                                        <Form.Control.Feedback type="invalid">
                                            {t(`tiers-payant.manage.form.insurance-card-file.errors.${formErrors.insuranceCardFile.message}`)}
                                        </Form.Control.Feedback>
                                    )}
                                </>}
                                <Form.Check // prettier-ignore
                                    type="checkbox"
                                    id="hasInsuranceCardFile"
                                    size="lg"
                                    label={t('tiers-payant.manage.form.has-insurance-card-file.label')}
                                    {...register("hasInsuranceCardFile")}
                                    disabled={loading}
                                    onChange={val => {
                                        setHasInsuranceCardFile(!val.target.checked)
                                    }}
                                />
                            </>)}
                        </Form.Group>
                    )}
                    {type === 'prosthesis_orthodontics' && (
                        <Form.Group className="mb-3" controlId="tiers-payant.medical-care-file">
                            <Form.Label>{t('tiers-payant.manage.form.medical-care-file.label')}</Form.Label>

                            {(hasMedicalCareFile && tiersPayant.medicalCareFile) && (
                                <div>
                                    {t('tiers-payant.manage.form.medical-care-file.already-downloaded')}
                                    <a target="_blank"
                                       href={`${apiURL}/api/tiers-payants/${tiersPayant.id}/file/${tiersPayant.insuranceCardFile}?token=${token}`}>
                                        {tiersPayant.medicalCareFile}</a>
                                    {!showMedicalCareFile &&
                                        <div><a style={{color: '#4287f5'}} onClick={() => setShowMedicalCareFile(!showMedicalCareFile)}>Le modifier ?</a></div>}
                                </div>
                            )}

                            {showMedicalCareFile && <>
                                {hasMedicalCareFile && <>
                                    <Form.Control
                                        type="file"
                                        placeholder={t('tiers-payant.manage.form.medical-care-file.place-holder')}
                                        size="lg"
                                        {...register("medicalCareFile", {
                                            required: 'error-empty',
                                        })}
                                        disabled={loading}
                                        isInvalid={formErrors && formErrors.medicalCareFile}
                                    />
                                    {formErrors && formErrors.medicalCareFile && (
                                        <Form.Control.Feedback type="invalid">
                                            {t(`tiers-payant.manage.form.medical-care-file.errors.${formErrors.medicalCareFile.message}`)}
                                        </Form.Control.Feedback>
                                    )}
                                </>}
                                <Form.Check // prettier-ignore
                                    type="checkbox"
                                    id="hasMedicalCareFile"
                                    size="lg"
                                    label={t('tiers-payant.manage.form.has-medical-care-file.label')}
                                    {...register("hasMedicalCareFile")}
                                    disabled={loading}
                                    onChange={val => {
                                        setHasMedicalCareFile(!val.target.checked)
                                    }}
                                />
                            </>}
                        </Form.Group>
                    )}
                </>}
            </Form>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.manage.button.cancel')}
                </Button>
                <Button variant="primary"  onClick={handleSubmit(onSubmit)}>
                    {t('tiers-payant.manage.button.next')}
                </Button>
            </Modal.Footer>
        </>
    )
}

export default Step1Document