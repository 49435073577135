import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert, Button, Form, Modal, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";

const Step7CancellationCare = ({tiersPayant, loading, handleClose, onSubmit, apiErrors}) => {
    const { t } = useTranslation();
    const [howToManage, setHowToManage] = useState(null)
    const [sendEmailAnyway, setSendEmailAnyway] = useState(null)
    const [emails, setEmails] = useState([])
    const [defaultEmails, setDefaultEmails] = useState([])

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
        setError,
        clearErrors
    } = useForm({})

    useEffect(() => {
        if (apiErrors !== null && Object.keys(apiErrors).length > 0) {
            Object.keys(apiErrors).map((key) => {
                setError(key, apiErrors[key])
            })
        }
    }, [apiErrors]);

    useEffect(() => {
        if (tiersPayant) {
            if (tiersPayant.howToManage !== null) {
                setValue('howToManage', tiersPayant.howToManage)
                setHowToManage(tiersPayant.howToManage)
            }
            if (tiersPayant.mailInformation !== null) {
                setValue('mailInformation', tiersPayant.mailInformation)
            }
            if (tiersPayant.sendEmailAnyway !== null) {
                setValue('sendEmailAnyway', (tiersPayant.sendEmailAnyway === true ? 'true' : 'false'))
                setSendEmailAnyway(tiersPayant.sendEmailAnyway)
            }

            const contactWithEmails = tiersPayant.customer.contacts.filter(contact => {
                return contact.email != null && contact.email !== ''
            })

            const options = contactWithEmails.map(contact => {
                const c = [];
                if (contact.role != null) {
                    c.push(contact.role)
                }
                if (contact.name != null) {
                    c.push(contact.name)
                }
                if (contact.email != null) {
                    c.push(contact.email)
                }

                return { label: c.join(' - '), value: contact.id }
            })
            setEmails(options)

            if (tiersPayant.sendTo !== null) {
                const sendToArray = tiersPayant.sendTo.split(';')
                const defaultOptions = options.filter(element => sendToArray.some(v => element.label.includes(v)))

                setDefaultEmails(defaultOptions)
                setValue('sendTo', defaultOptions)
            }
        }
    }, [tiersPayant]);

    const validateSubmit = ({howToManage, sendEmailAnyway, mailInformation, sendTo}) => {
        const sendEmailAnywayBool = sendEmailAnyway === null ? null : (sendEmailAnyway === 'true');
        const sendToIds = sendTo === undefined ? null : Array.from(sendTo, (contact => contact.value))

        onSubmit({
            howToManage,
            sendEmailAnyway: sendEmailAnywayBool,
            mailInformation,
            sendTo: sendToIds,
        })
    }

    return (
        <Form onSubmit={handleSubmit(validateSubmit)}>
            <Alert variant="warning">
                <div className="alert-message">
                    <span className="block sm:inline">
                        Le dossier ne peut pas être géré.
                    </span>
                </div>
            </Alert>

            <Form.Group className="mb-3" controlId="tiers-payant.how-to-manage">
                <Form.Label>{t('tiers-payant.manage.form.how-to-manage-cancellation-care.label')}</Form.Label>
                <Controller
                    name="howToManage"
                    control={control}
                    render={({ field, value, name, ref }) => (
                        <ToggleButtonGroup
                            type="radio"
                            value={field.value}
                            onChange={(val) => {
                                field.onChange(val)
                                setHowToManage(val)
                            }}
                            name="howToManage"
                            ref={field.ref}
                            style={{marginLeft: '10px'}}
                        >
                            <ToggleButton id="howToManage-btn-1" value='mail' variant='outline-info'>Mail au client (demande d'information)</ToggleButton>
                            <ToggleButton id="howToManage-btn-2" value='closed' variant='outline-info'>Clôturer le dossier</ToggleButton>
                        </ToggleButtonGroup>
                    )}
                    size="lg"
                    {...register("howToManage", {
                        required: 'error-empty',
                    })}
                />
            </Form.Group>

            {howToManage === 'closed' && <Form.Group className="mb-3" controlId="tiers-payant.send-email-anyway">
                <Form.Label>{t('tiers-payant.manage.form.send-email-anyway.label')}</Form.Label>
                <Controller
                    name="sendEmailAnyway"
                    control={control}
                    render={({ field, value, name, ref }) => (
                        <ToggleButtonGroup
                            type="radio"
                            value={field.value}
                            name={field.name}
                            ref={field.ref}
                            style={{marginLeft: '10px'}}
                            onChange={(val) => {
                                field.onChange(val)
                                setSendEmailAnyway(val === 'true')
                            }}
                        >
                            <ToggleButton id="sendEmailAnyway-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                            <ToggleButton id="sendEmailAnyway-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                        </ToggleButtonGroup>
                    )}
                    size="lg"
                    {...register("sendEmailAnyway", {
                        required: 'error-empty',
                    })}
                />
            </Form.Group> }

            {(howToManage === 'mail' || sendEmailAnyway === true) && <>
                <Form.Group className="mb-3" controlId="tiers-payant.mail-information">
                    <Form.Label>{t('tiers-payant.manage.form.mail-information.label')}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        type="textarea"
                        size="lg"
                        {...register("mailInformation", {
                            required: 'error-empty',
                        })}
                        isInvalid={formErrors && formErrors.mailInformation}
                    />
                    {formErrors && formErrors.mailInformation && (
                        <Form.Control.Feedback type="invalid">
                            {t(`tiers-payant.manage.form.mail-information.errors.${formErrors.mailInformation.message}`)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="tiers-payant.send-to">
                    <Form.Label>{t('tiers-payant.manage.form.send-to.label')}</Form.Label>
                    <Controller
                        name="sendTo"
                        control={control}
                        size="lg"
                        {...register("sendTo", {
                            //required: 'error-empty',
                        })}
                        render={({ field, value, name, ref }) => (
                            <Select
                                fieldName={field.name}
                                defaultValue={defaultEmails}
                                placeholder={t('tiers-payant.manage.form.send-to.placeholder')}
                                inputRef={field.ref}
                                options={emails}
                                value={emails.find((c) => c.value === field.value)}
                                onChange={val => field.onChange(val)}
                                isInvalid={formErrors && formErrors.sendTo}
                                className={`p-0 form-control form-control-lg ${formErrors && formErrors.sendTo ? ' is-invalid' : ''}`}
                                isMulti
                            />)
                        }
                    />
                    {formErrors && formErrors.sendTo && (
                        <Form.Control.Feedback type="invalid">
                            {t(`tiers-payant.manage.form.send-to.errors.${formErrors.sendTo.message}`)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            </>}

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.manage.button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('tiers-payant.manage.button.next')}
                </Button>
            </Modal.Footer>
        </Form>
    )
}

export default Step7CancellationCare